body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    font-family: 'Roboto', 'Script MT Bold', sans-serif;
}

p {
    font-size: 19px;
}

@media (max-width: 768px) {
    .modal-dialog {
        max-width: 100%;
        margin: 1rem;
    }
}

li {
    font-size: 20px;
}

h1, h2, h3, h4, h5, h6 {
    text-transform: uppercase;
    font-weight: bolder;
}

.border1 {
    border-bottom: 5px solid rgba(0, 0, 0, 0.3);
}

#nav-link {
    color: #999999;
    transition: color 0.3s;
    padding: 6px;
}

#nav-link:hover {
    color: #f0f0f0;
}

#nav-link.active {
    color: #f0f0f0;
}

#button-language {
    background: #050505;
    color: #999999;
    font-size: 20px;
    border: none;
    transition: color 0.3s;
}

#button-language:hover {
    background: #181818;
    border: none;
    color: #f0f0f0;
}

.countdown-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.progress-bar {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.value-text {
    font-weight: bold;
    text-align: center;
    font-family: 'Courier New' ,'Script MT Bold', sans-serif;
}

.label-text {
    font-weight: bold;
    text-align: center;
    font-family: 'Courier New', 'Script MT Bold', sans-serif;
}

@media (min-width: 600px) {
    .progress-bar {
        width: 125px;
        height: 125px;
    }

    .value-text {
        font-size: 1.2em;
    }

    .label-text {
        font-size: 0.8em;
    }
}

@media (min-width: 900px) {
    .progress-bar {
        width: 200px;
        height: 200px;
    }

    .value-text {
        font-size: 1.6em;
    }

    .label-text {
        font-size: 1em;
    }
}

@media (min-width: 1200px) {
    .progress-bar {
        width: 200px;
        height: 200px;
    }

    .value-text {
        font-size: 1.8em;
    }

    .label-text {
        font-size: 1.1em;
    }
}




.shadow-red {
    box-shadow: 0 0 10px #fa4d64;
}

.shadow-blue {
    box-shadow: 0 0 10px #09dceb;
}

.shadow-yellow {
    box-shadow: 0 0 10px #ecf00a;
}

.shadow-green {
    box-shadow: 0 0 10px #0be60b;
}
